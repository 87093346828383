
import bugsnagClient from 'Src/js/global/bugsnag';
import clickOutside from '../../directives/click-outside';
import globalData from '../../global/window-data';
import hawkService from 'JS/hawk/HawkSearchService';
import Icon from 'JS/components/Icon.vue';
import SearchSuggestions from './SearchSuggestions.vue';
import trapFocus from '../../directives/trap-focus';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { v4 as genUUID } from 'uuid';
import { IHawkSuggestionsResult } from 'Src/js/types/HawkSearch';


@Component({
	name: 'SearchBar',
	components: {
		Icon,
		SearchSuggestions
	},
	directives: {
		clickOutside,
		trapFocus
	}
})
export default class SearchBar extends Vue {

	$refs: {
		form: HTMLFormElement;
		suggestions: SearchSuggestions;
		input: HTMLInputElement;
	}

	keyword: string = '';
	triggerAriaMessage = false;
	guuid = genUUID();
	testGroup: number = 0;
	suggestTimer: NodeJS.Timeout = null;
	id: string = 'searchInput_' + Date.now(); // Prevent autocomplete based on name/id
	suggestions: IHawkSuggestionsResult | null = null;
	suggestionIndex: number = 0;

	get searchBarState(): string {
		return this.suggestions ? "Suggestions Expanded Press Escape to Close" : "Suggestions Collapsed";
	}

	submitValidation(event) {
		if (!this.keyword) {
			this.triggerAriaMessage = true;
			event.preventDefault();
		}
	};

	handleKeyPress(ev: KeyboardEvent) {
		if (!this.suggestions) return;
		switch (ev.key) {
			case 'Escape':
				this.closeSuggestions();
				this.$refs.input.focus();
				break;
			case 'ArrowDown':
				ev.preventDefault();
				this.suggestionIndex++;
				this.focusCategorySuggestion();
				break;
			case 'ArrowUp':
				ev.preventDefault();
				this.suggestionIndex--;
				this.focusCategorySuggestion();
				break;
			case 'Enter':
				this.closeSuggestions();
			default:
				break;
		}
	}

	closeSuggestions() {
		if (!this.suggestions) return;
		this.suggestions = null;
		this.suggestionIndex = 0;
	}

	focusCategorySuggestion() {
		const el = this.$refs.suggestions?.$el;
		if (!el) return;
		const suggestions = el.querySelectorAll('a').length;
		if (!el.contains(document.activeElement)) this.suggestionIndex = 0;
		else if (this.suggestionIndex < 0) this.suggestionIndex = suggestions - 1;
		else if (this.suggestionIndex + 1 > suggestions) this.suggestionIndex = 0;
		el.querySelectorAll('a')[this.suggestionIndex]?.focus();
	}

	async getHawkSuggestions() {
		// BEGIN: autosuggest popular search A/B test
		clearTimeout(this.suggestTimer);
		// END: autosuggest popular search A/B test

		if (!this.keyword || this.keyword.length < 3) {
			this.closeSuggestions();
		}
		try {
			const suggestions = await hawkService.getSuggestions(this.keyword) as IHawkSuggestionsResult;
			if (!suggestions?.Products?.length && !suggestions?.Categories?.length && !suggestions?.Popular?.length) {
				this.suggestions = null;
			} else {
				this.suggestions = suggestions;
				this.suggestionIndex = 0;

				// BEGIN: autosuggest popular search A/B test
				if (suggestions.Popular.length > 0) {
					this.suggestTimer = setTimeout(() => {
						// @ts-ignore
						window.blainGtmDataLayer.push({
							'event': "search_autosuggest"
						});
					}, 1500);
				}
				// END: autosuggest popular search A/B test
			}
		} catch (e) {
			if (!e) return; // Don't message-less errors for failed network requests to hawk
			bugsnagClient.notify(e);
		}
	}

	beforeMount() {
		const searchKeyword = new URLSearchParams(window.location.search).get('keyword');
		if (searchKeyword) {
			this.keyword = searchKeyword;
		}
	}

	mounted() {
		this.testGroup = globalData.testGroup;

		// begin watching after the keyword may have been set by beforeMount
		// otherwise it'll query for suggestions on page load
		this.$watch('keyword', this.onKeywordChanged);
	}

	onKeywordChanged() {
		this.getHawkSuggestions();
		if (this.keyword) {
			this.triggerAriaMessage = false;
		}
	}

}
